import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CartList from "./CartList";
import { useGetCart } from "../../hooks/useManageCart";
import { useEffect, useMemo, useState } from "react";
import Loader from "../Loader";
import { useRegenRewards } from "../../hooks/useManageRewards";
import OutOfStockDialog from "./OutOfStockDialog";
import { getOutOfStockList } from "../checkout/helper";
import { useCallback } from "react";
import { beginCheckout_GA } from "../../utils/GoogleAnalytics";
import EmptyCart from "./EmptyCart";
import CartFooter from "./CartFooter";
import CartHeaedr from "./CartHeader";
import css from "../../styles_web/CartMain.module.css";
import { useQueryClient } from "react-query";

function CartMain() {
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [outOfStockOpen, setOutOfStockOpen] = useState(false);
  const location = useLocation();
  const queryClient = useQueryClient();

  const { data, isLoading, isError, error } = useGetCart(
    location?.state?.backgroundLocation?.pathname === "/checkout",
    from === "email"
  );
  const { data: rewardsData, isFetching } = useRegenRewards();

  useEffect(() => {
    if (isError) {
      setShowAlert(true);
    }
  }, [isError]);

  const handleEmptyBackgroundPath = useCallback(() => {
    if (!location?.state) {
      navigate(from ? `/cart?from=${from}` : "/cart", {
        state: {
          backgroundLocation: {
            hash: "",
            key: "default",
            pathname: "/",
            search: "",
            state: null,
          },
        },
      });
    }
  }, [location, navigate, from]);

  useEffect(() => {
    handleEmptyBackgroundPath();
  }, [handleEmptyBackgroundPath]);

  const outOfStockItems = useMemo(() => {
    if (data) {
      return getOutOfStockList(data?.items);
    }
    return [];
  }, [data]);

  const cartItems = useMemo(() => {
    if (data && data.items) {
      const cartData = data.items.sort((item1, item2) =>
        item1.sku > item2.sku ? 1 : item1.sku < item2.sku ? -1 : 0
      );
      return <CartList cartData={cartData} />;
    }
  }, [data]);

  const handleOutOfStockSuccess = () => {
    beginCheckout_GA(data);
    navigate(`/checkout`);
  };

  const handleBack = () => {
    if (location?.state?.backgroundLocation?.pathname) {
      const searchPath = location.state.backgroundLocation.search
        ? location.state.backgroundLocation.search
        : "";
      navigate(location.state.backgroundLocation.pathname + searchPath);
    } else {
      navigate(-1);
    }
  };

  const handleBackdropClick = () => {
    handleBack();
  };

  const handleKeyDown = (event) => {
    if (event?.key === "Escape") {
      handleBack();
    }
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  const handleCheckout = () => {
    if (outOfStockItems.length > 0) {
      setOutOfStockOpen(true);
    } else {
      queryClient.cancelQueries(["cart"]);
      beginCheckout_GA(data);
      navigate(`/checkout`);
    }
  };

  const continueShopping = () => {
    navigate("/products");
  };

  const closeOutOfStockDialog = () => {
    setOutOfStockOpen(false);
  };

  if (isLoading || !data) {
    return <Loader />;
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={true}
        PaperProps={{
          sx: {
            width: { xs: "100%", md: "50%", lg: "40%", xl: "30%" },
          },
        }}
        SlideProps={{ className: "overlay-slide-open" }}
        onBackdropClick={handleBackdropClick}
        onKeyDown={handleKeyDown}
        disableRestoreFocus={true}
      >
        <Box className={css.cart}>
          {data?.items?.length === 0 ? (
            <EmptyCart handleBack={handleBack} />
          ) : (
            <>
              <Box className={css.headerAndBody}>
                <CartHeaedr
                  data={data}
                  rewardsData={rewardsData}
                  handleBackdropClick={handleBackdropClick}
                />
                <Box className={css.cartItems}>{cartItems}</Box>
              </Box>
              <CartFooter
                rewardsData={rewardsData}
                isFetching={isFetching}
                data={data}
                handleCheckout={handleCheckout}
                continueShopping={continueShopping}
              />
            </>
          )}
        </Box>
      </Drawer>
      <Snackbar open={showAlert} autoHideDuration={4000} onClose={handleClose}>
        <Alert severity="error">{error?.message}</Alert>
      </Snackbar>
      {outOfStockOpen && outOfStockItems?.length > 0 && (
        <OutOfStockDialog
          items={outOfStockItems}
          handleClose={closeOutOfStockDialog}
          handleOutOfStockSuccess={handleOutOfStockSuccess}
        />
      )}
    </>
  );
}

export default CartMain;
